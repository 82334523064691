import { Websites } from "./collections/websites";
import { ChatbotWidget } from "./chatbot_widget";
import { Chatbot } from "./collections/chatbot/chatbots";

export type CodeBuilderFunction = (chatbotWidget?: ChatbotWidget, chatbot?: Chatbot, website?: Partial<Websites>) => string;

export type CodeBuilderFunctionInternal = (chatbotWidget?: ChatbotWidget, chatbot?: Chatbot, website?: Partial<Websites>, version?: string) => string;

const createEmptyCode = () => {
    return "console.log('Chat Disabled');"
}

const createActiveChatbotCode = (chatbot: Chatbot, chatbotWidget: ChatbotWidget, version: string) => {
    return `
        (function (window, document) {
            if (window.ddChatbot) {
                console.error('Deardoc Chatbot embed already included');
                return;
            }
            window.ddChatbot = {},
                m = ['init', 'debug'];
            window.ddChatbot._c = [];
            m.forEach(me => window.ddChatbot[me] = function () {
                window.ddChatbot._c.push([me, arguments])
            });
            var elt = document.createElement('script');
            elt.type = "text/javascript";
            elt.async = true;
            elt.src = "${chatbotWidget.baseURL}/shim.js?v=${version}";
            var before = document.getElementsByTagName('script')[0]; before.parentNode.insertBefore(elt, before);
        })(window, document, undefined);
        ddChatbot.init('${chatbot.apiKey}','${chatbotWidget.baseURL}','${version}');
    `;
}

export const createChatbotCode: CodeBuilderFunction = (chatbotWidget?: ChatbotWidget, chatbot?: Chatbot, website?: Partial<Websites>) => {
    if (!chatbot || !website || !chatbotWidget || !website.chatbot_enabled) {
        return createEmptyCode();
    }
    return createActiveChatbotCode(chatbot, chatbotWidget, "1.0.3");
}

export const createInternalChatbotCode: CodeBuilderFunctionInternal = (chatbotWidget?: ChatbotWidget, chatbot?: Chatbot, website?: Partial<Websites>, version?: string) => {
    if (!chatbot || !chatbotWidget) {
        return createEmptyCode();
    }
    return createActiveChatbotCode(chatbot, chatbotWidget, version || "1.0.3");
}
