import {
  buildCollection,
  buildEntityCallbacks,
  buildProperty,
  EntityOnSaveProps,
  EntityValues,
  User
} from "@firecms/core";

export type KnowledgeChunk = {
  content: string;
  trainingFinished: boolean;
  updatedAt: Date;
  tokens: number;
}

const knowledgeChunkCallbacks = buildEntityCallbacks<KnowledgeChunk>({
  onPreSave(entitySaveProps: EntityOnSaveProps<KnowledgeChunk>): Promise<Partial<EntityValues<KnowledgeChunk>>> | Partial<EntityValues<KnowledgeChunk>> {
    entitySaveProps.values.trainingFinished = false;
    entitySaveProps.values.updatedAt = new Date();
    if ("embedding" in entitySaveProps.values) {
      delete entitySaveProps.values.embedding;
    }
    return entitySaveProps.values;
  }
});

export const knowledgeCollection = buildCollection<KnowledgeChunk>({
  name: "Knowledge",
  singularName: "Knowledge Chunk",
  textSearchEnabled: false,
  path: "knowledge",
  id: "knowledge",
  icon: "lightbulb",
  group: "Admin",
  permissions: ({ authController, user }) => ({
    read: true,
    edit: true,
    create: true,
    delete: true
  }),
  callbacks: knowledgeChunkCallbacks,
  properties: {
    content: buildProperty({
      name: "Content",
      validation: { required: true, max: 2048 },
      dataType: "string",
      multiline: true,
      longText: true,
      longTextRows: 5
    }),
    trainingFinished: buildProperty({
      name: "Training Finished",
      validation: { required: false },
      dataType: "boolean",
      readOnly: true,
      editable: false
    }),
    updatedAt: buildProperty({
      name: "Updated At",
      validation: { required: false },
      dataType: "date",
      readOnly: true,
      editable: false
    }),
    tokens: buildProperty({
      name: "Tokens",
      validation: { required: false },
      dataType: "number",
      readOnly: true,
      editable: false
    })
  }
})
