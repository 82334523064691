import React from "react";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { FieldHelperText, FieldProps, useModeController } from "@firecms/core";
import "react18-json-view/src/style.css"
import { ExpandablePanel } from "@firecms/ui";

interface JSONTextFieldProps {
  editable: boolean
}

export default function JSONTextField({
                                        property,
                                        value,
                                        setValue,
                                        setFieldValue,
                                        customProps,
                                        touched,
                                        includeDescription,
                                        showError,
                                        error,
                                        isSubmitting,
                                        context, // the rest of the entity values here
                                        ...props
                                      }: FieldProps) {

  const { mode } = useModeController();
  const internalValue = { ...value };
  return (
    <>
      <ExpandablePanel initiallyExpanded={true}
                       title={property.name}
                       className={"px-2 md:px-4 pb-2 md:pb-4 pt-1 md:pt-2"}>
        <span>{error}</span>

        <CodeMirror value={JSON.stringify(internalValue, null, "\t")} height="200px" extensions={[json()]} theme={mode} onChange={(value, viewUpdate) => {
          const valueToSet = JSON.parse(value);
          const originalObjectKeys = Object.keys(internalValue);
          for (const actualKey of originalObjectKeys) {
            if (internalValue[actualKey] && !valueToSet[actualKey]) {
              valueToSet[actualKey] = undefined;
            }
          }
          setFieldValue("body", valueToSet);
        }}/>;
        <FieldHelperText includeDescription={includeDescription}
                         showError={showError}
                         error={error}
                         property={property}/>
      </ExpandablePanel>

    </>

  );

}
