import {
  buildProperty
} from "@firecms/core";
import { v4 as uuidv4 } from "uuid";
import { Assistant, Customization, CustomProperty } from "./chatbots";
import HexColorTextField from "../../custom/HexColorTextField";

export const chatbotCollectionProperties = {
  name: buildProperty({
    name: "Name",
    validation: { required: true },
    dataType: "string"
  }),
  description: buildProperty({
    name: "Description",
    validation: { required: false },
    dataType: "string"
  }),
  tags: buildProperty({
    name: "Tags",
    expanded: false,
    validation: { required: false },
    dataType: "array",
    previewAsTag: true,
    of: {
      dataType: "string"
    }
  }),
  prompt: buildProperty({
    name: "Prompt",
    validation: { required: false },
    dataType: "string",
    multiline: true,
    longText: true,
    longTextRows: 5,
    hideFromCollection: true
  }),
  restrictions: buildProperty({
    name: "Restrictions",
    validation: { required: false },
    dataType: "string",
    multiline: true,
    longText: true,
    longTextRows: 5,
    hideFromCollection: true
  }),
  assistant: buildProperty<Assistant>({
    name: "Assistant",
    validation: { required: false },
    dataType: "map",
    expanded: false,
    properties: {
      name: buildProperty({
        name: "Name",
        validation: { required: false },
        dataType: "string"
      }),
      avatar: buildProperty({
        name: "Avatar",
        validation: { required: false },
        dataType: "string",
        storage: {
          storagePath: "images",
          acceptedFiles: ["image/*"],
          maxSize: 1024 * 1024,
          metadata: {
            cacheControl: "max-age=1000000"
          },
          fileName: (context) => {
            return uuidv4();
          }
        }
      })
    }
  }),
  customization: buildProperty<Customization>({
    name: "Customization",
    validation: { required: false },
    expanded: false,
    previewProperties: ["company_logo"],
    dataType: "map",
    properties: {
      main_color: buildProperty({
        name: "Main Color",
        validation: { required: false, matches: "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$" },
        description: "Color in hexadecimal format, example: #2EC4B6",
        dataType: "string",
        defaultValue: "#2EC4B6",
        Field: HexColorTextField
      }),
      company_logo: buildProperty({
        name: "Company Logo",
        validation: { required: false },
        dataType: "string",
        storage: {
          storagePath: "images",
          acceptedFiles: ["image/*"],
          maxSize: 1024 * 1024,
          metadata: {
            cacheControl: "max-age=1000000"
          },
          fileName: (context) => {
            return uuidv4();
          }
        }
      }),
      chat_logo: buildProperty({
        name: "Chat Logo",
        validation: { required: false },
        dataType: "string",
        storage: {
          storagePath: "images",
          acceptedFiles: ["image/*"],
          maxSize: 1024 * 1024,
          metadata: {
            cacheControl: "max-age=1000000"
          },
          fileName: (context) => {
            return uuidv4();
          }
        }
      }),
      chat_logo_size: buildProperty({
        name: "Chat Logo Size",
        description: "Size of the chat logo in percentage to the total bubble",
        validation: { required: false, min: 54, max: 100 },
        dataType: "number",
        defaultValue: 55
      }),
      notification_bubble_color: buildProperty({
        name: "Bubble notification Color",
        longName: "Notification Bubble Color",
        validation: { required: false, matches: "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$" },
        description: "Color used for the notification bubble. Must be hexadecimal, example: #FF6B6B",
        defaultValue: "#FF6B6B",
        dataType: "string",
        Field: HexColorTextField
      }),
      chat_autostart: buildProperty({
        name: "Chat Autostart",
        description: "If enabled, the chat will start automatically when the user enters the page",
        validation: { required: false },
        defaultValue: true,
        dataType: "boolean"
      }),
      chat_autostart_time: buildProperty({
        name: "Chat Autostart Time",
        description: "Time in miliseconds before the chat starts automatically",
        validation: { required: false },
        dataType: "number",
        defaultValue: 5000
      }),
      position: buildProperty({
        name: "Position",
        validation: { required: false },
        dataType: "string",
        enumValues: {
          right: "Right",
          left: "Left"
        },
        defaultValue: "right"
      }),
      lateral_distance: buildProperty({
        name: "Lateral Distance",
        validation: { required: false },
        dataType: "number",
        defaultValue: 20
      }),
      bottom_distance: buildProperty({
        name: "Bottom Distance",
        validation: { required: false },
        dataType: "number",
        defaultValue: 20
      }),
      custom_font_name: buildProperty({
        name: "Custom Font Name",
        validation: { required: false },
        dataType: "string"
      }),
      custom_regular_font_woff: buildProperty({
        name: "Custom Regular Font WOFF",
        validation: { required: false },
        dataType: "string",
        storage: {
          storagePath: "customfonts",
          acceptedFiles: [".woff"],
          metadata: {
            cacheControl: "max-age=1000000"
          },
          fileName: (context) => {
            return uuidv4() + ".woff";
          }
        }
      }),
      custom_regular_font_woff2: buildProperty({
        name: "Custom Regular Font WOFF2",
        validation: { required: false },
        dataType: "string",
        storage: {
          storagePath: "customfonts",
          acceptedFiles: [".woff2"],
          metadata: {
            cacheControl: "max-age=1000000"
          },
          fileName: (context) => {
            return uuidv4() + ".woff2";
          }
        }
      }),
      custom_bold_font_woff: buildProperty({
        name: "Custom Bold Font WOFF",
        validation: { required: false },
        dataType: "string",
        storage: {
          storagePath: "customfonts",
          acceptedFiles: [".woff"],
          metadata: {
            cacheControl: "max-age=1000000"
          },
          fileName: (context) => {
            return uuidv4() + ".woff";
          }
        }
      }),
      custom_bold_font_woff2: buildProperty({
        name: "Custom Bold Font WOFF2",
        validation: { required: false },
        dataType: "string",
        storage: {
          storagePath: "customfonts",
          acceptedFiles: [".woff2"],
          metadata: {
            cacheControl: "max-age=1000000"
          },
          fileName: (context) => {
            return uuidv4() + ".woff2";
          }
        }
      })
    }
  }),
  initialMessage: buildProperty({
    name: "Initial Message",
    validation: { required: false },
    dataType: "string",
    multiline: true,
    longText: true,
    longTextRows: 5,
    hideFromCollection: true
  }),
  initialSuggestionChips: buildProperty({
    name: "Initial Suggestion Chips",
    validation: { required: false },
    expanded: false,
    dataType: "array",
    of: {
      dataType: "string"
    },
    hideFromCollection: true
  }),
  conversationCompleted: buildProperty({
    name: "Conversation Completed",
    defaultValue: "The chat is completed when the assistant or the customer said goodbye. The chat is also considered completed if the assistant says to the customer that it will be contacted shortly",
    longDescription: "Description of when a chat is completed. This will be added to the AI model and will figure out when a chat is done.",
    validation: { required: false },
    dataType: "string",
    multiline: true,
    longText: true,
    longTextRows: 3,
    hideFromCollection: true
  }),
  customProperties: buildProperty({
    name: "Custom Variables",
    validation: { required: false },
    expanded: false,
    dataType: "array",
    of: buildProperty<CustomProperty>({
      name: "Custom Property",
      dataType: "map",
      previewProperties: ["name", "type"],
      properties: {
        name: buildProperty({
          name: "Name",
          validation: { required: true },
          dataType: "string"
        }),
        type: buildProperty({
          name: "Type",
          validation: { required: true },
          dataType: "string",
          enumValues: {
            string: "String",
            number: "Number",
            boolean: "Boolean",
            date: "Date"
          }
        }),
        description: buildProperty({
          name: "Description",
          validation: { required: false },
          dataType: "string"
        })
      }
    })
  }),
  createdAt: buildProperty({
    name: "Created At",
    validation: { required: false },
    dataType: "date",
    readOnly: true,
    autoValue: "on_create"
  }),
  updatedAt: buildProperty({
    name: "Updated At",
    validation: { required: false },
    autoValue: "on_update",
    dataType: "date",
    readOnly: true,
    editable: false,
    hideFromCollection: true
  }),
  updatedBy: buildProperty({
    name: "Updated By",
    validation: { required: false },
    dataType: "map",
    expanded: false,
    previewProperties: ["displayName"],
    properties: {
      uid: buildProperty({
        name: "UID",
        validation: { required: false },
        dataType: "string"
      }),
      displayName: buildProperty({
        name: "Display Name",
        validation: { required: false },
        dataType: "string"
      }),
      email: buildProperty({
        name: "Email",
        validation: { required: false },
        dataType: "string"
      })
    },
    readOnly: true,
    editable: false,
    hideFromCollection: true
  }),
  salesforceId: buildProperty({
    name: "Salesforce ID",
    validation: { required: true },
    dataType: "string",
    hideFromCollection: true
  }),
  apiKey: buildProperty({
    name: "API Key",
    validation: { required: false },
    unique: true,
    dataType: "string",
    readOnly: true,
    editable: false,
    hideFromCollection: true
  }),
  status: buildProperty({
    name: "Chatbot status",
    validation: { required: true },
    dataType: "string",
    enumValues: {
      live: "Live",
      disabled: "Disabled"
    },
    defaultValue: "live"
  })
};
