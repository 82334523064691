import algoliasearch, { SearchClient } from "algoliasearch";
import { buildAlgoliaSearchController, performAlgoliaTextSearch } from "@firecms/firebase";
import config from "./config";

const client: SearchClient | undefined = algoliasearch(config.ALGOLIA_APP_ID, config.ALGOLIA_API_KEY);

const chatbotsIndex = client && client.initIndex("chatbots");
const websitesIndex = client && client.initIndex("websites");

export const algoliaSearchControllerBuilder = buildAlgoliaSearchController({
  isPathSupported: (path) => {
    return ["chatbots", "websites"].includes(path);
  },
  search: ({
             path,
             searchString
           }) => {
    if (path === "chatbots")
      return chatbotsIndex && performAlgoliaTextSearch(chatbotsIndex, searchString);
    if (path === "websites")
      return websitesIndex && performAlgoliaTextSearch(websitesIndex, searchString);
    return undefined;
  }
});
