export interface ChatbotWidget {
  environment: string;
  name: string;
  baseURL: string;
}

export const chatbotWidgets: ChatbotWidget[] = [
  {
    environment: "production",
    name: "Production",
    baseURL: "https://chat.getdeardoc.com/build"
  },
  {
    environment: "staging",
    name: "Staging",
    baseURL: "https://ai-chat-js-staging.web.app/build"
  },
  {
    environment: "dev",
    name: "Development",
    baseURL: "https://ai-chat-js.web.app/build"
  }
]

export const getEnumMapOfChatbotWidgets = (): Record<string, string> => {
  const enumMap: Record<string, string> = {};
  chatbotWidgets.forEach((widget) => {
    enumMap[widget.environment] = widget.name;
  });
  return enumMap;
}
