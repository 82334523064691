import { buildCollection, buildProperty } from "@firecms/core";

export type Member = {
  name: string;
  email: string;
  isAdmin: boolean;
}

export const teamMembersCollection = buildCollection<Member>({
  name: "Team",
  singularName: "Member",
  path: "team",
  exportable: false,
  id: "team",
  icon: "group",
  group: "Admin",
  permissions: ({ authController, user }) => ({
    read: true,
    edit: authController.extra?.roles?.includes("admin"),
    create: authController.extra?.roles?.includes("admin"),
    delete: authController.extra?.roles?.includes("admin")
  }),
  properties: {
    name: buildProperty({
      name: "Name",
      validation: { required: true },
      dataType: "string"
    }),
    email: buildProperty({
      name: "Email",
      validation: { required: false },
      dataType: "string"
    }),
    isAdmin: buildProperty({
      name: "Is Admin",
      validation: { required: false },
      dataType: "boolean"
    })
  }
})
