//import { DoorBack, SystemSecurityUpdateGoodOutlined } from "@mui/icons-material";

export const firebaseProductionConfig = {
    apiKey: "AIzaSyC9hKksgZ73o7lKvJLBBUQNCRn0wkOsO1o",
    authDomain: "ai-chat-next.firebaseapp.com",
    projectId: "ai-chat-next",
    storageBucket: "ai-chat-next.appspot.com",
    messagingSenderId: "557897744292",
    appId: "1:557897744292:web:4f702b271d4cd0575b4b31",
    measurementId: "G-9DLBJZMRCN"
}

export const firebaseStagingConfig = {
    apiKey: "AIzaSyCdXl5G8ojBaY9OXrbwXsd4Y8bdO5ZUot0",
    authDomain: "ai-chat-next-426814.firebaseapp.com",
    projectId: "ai-chat-next-426814",
    storageBucket: "ai-chat-next-426814.appspot.com",
    messagingSenderId: "1045779058540",
    appId: "1:1045779058540:web:85267ceb9ba81782526d49",
    measurementId: "G-QK0Q7BST6E"
};
