import { Member } from "./collections/team";
import { Authenticator } from "@firecms/core";
import { FirebaseUserWrapper } from "@firecms/firebase";

export const authenticator: Authenticator<FirebaseUserWrapper> = async ({ user, authController, dataSourceDelegate }) => {
  if (!user?.email) return false;
  else {
    const entities = await dataSourceDelegate.fetchCollection<Member>({
      path: "team",
      filter: { email: ["==", user.email] }
    });
    if (Boolean(entities) && entities.length > 0) {
      authController.setExtra({
        roles: [entities[0].values.isAdmin ? "admin" : "editor"]
      });
      return true;
    }
    return false;
  }
};
