import {
  Entity,
  EntityCustomView,
  useModeController
} from "@firecms/core";
import React, { useEffect, useRef } from "react";
import { createInternalChatbotCode } from "../code_gen";
import { chatbotWidgets } from "../chatbot_widget";
import config from "../config";
import { Chatbot } from "../collections/chatbot/chatbots";

export const chatbotPreview: EntityCustomView = {
  key: "preview",
  name: "Chatbot Preview",
  Builder: ({
    collection,
    entity,
    modifiedValues,
    formContext
  }) => (

    <ChatbotPreviewComponent entity={entity} />

  )
}

interface ChatbotPreviewComponentProps {
  entity: Entity<Chatbot> | undefined;
}

export const ChatbotPreviewComponent: React.FunctionComponent<ChatbotPreviewComponentProps> = ({
  entity
}) => {
  const [activeTab, setActiveTab] = React.useState<string>("desktop"); // ["desktop", "mobile"]
  //add random word to key to force iframe reload
  const [key, setKey] = React.useState<string>(Math.random().toString(36).substring(7));
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const iframeMobileRef = useRef<HTMLIFrameElement>(null);
  const { mode } = useModeController();

  const setupIframes = () => {
    if (activeTab === "desktop") {
      const iframeDesktop = iframeRef.current;
      const iframeMobile = iframeMobileRef.current;
      if (iframeMobile) {
        iframeMobile.src = "about:blank";
      }
      //empty iframe if was not empty
      if (iframeDesktop) {
        const iframeDoc = iframeDesktop.contentDocument || iframeDesktop.contentWindow?.document;
        const getCurrentChatbotWidgets = chatbotWidgets.filter((widget) => widget.environment === (config.APP_ENV !== "production" ? "staging" : "production"));
        if (entity !== undefined) {
          const chatbotWidget = createInternalChatbotCode(getCurrentChatbotWidgets[0], entity.values, undefined, key);
          if (iframeDoc) {
            iframeDoc.open();
            iframeDoc.write(`
          <html>
          <head>
            <style>
            body {
              background-color: ${mode === "light" ? "white" : "black"};
            }
            </style>
            <script>
              ${chatbotWidget}
            </script>
          </head>
          <body>
            <!-- Add any other content you need inside the iframe here -->
          </body>
          </html>
        `);

            iframeDoc.close();
          }
        }

      }
    } else {
      const iframeDesktop = iframeRef.current;
      const iframeMobile = iframeMobileRef.current;
      if (iframeDesktop) {
        iframeDesktop.src = "about:blank";
      }
      if (iframeMobile) {
        const iframeDoc = iframeMobile.contentDocument || iframeMobile.contentWindow?.document;
        const getCurrentChatbotWidgets = chatbotWidgets.filter((widget) => widget.environment === (config.APP_ENV !== "production" ? "staging" : "production"));
        if (entity !== undefined) {
          const chatbotWidget = createInternalChatbotCode(getCurrentChatbotWidgets[0], entity.values, undefined, key);
          if (iframeDoc) {
            iframeDoc.open();
            iframeDoc.write(`
          <html>
          <head>
            <style>
            body {
              background-color: ${mode === "light" ? "white" : "black"};
            }
            </style>
            <script>
              var simulateMobile = true;
              ${chatbotWidget}
            </script>
          </head>
          <body>
            <!-- Add any other content you need inside the iframe here -->
          </body>
          </html>
        `);

            iframeDoc.close();
          }
        }

      }
    }
  }

  useEffect(() => {

    setupIframes();

    //when the entity unload, need to remove sessionstorage items
    return () => {
      sessionStorage.removeItem("ddc-id");
      sessionStorage.removeItem("ddc-ct");
    }

  }, [entity, activeTab, mode]);

  const restartChatbot = () => {

    sessionStorage.removeItem("ddc-id");
    sessionStorage.removeItem("ddc-ct");

    setKey(Math.random().toString(36).substring(7))

    const iframeDesktop = iframeRef.current;
    const iframeMobile = iframeMobileRef.current;

    //empty iframe if was not empty
    if (iframeDesktop) {
      const iframeDoc = iframeDesktop.contentDocument || iframeDesktop.contentWindow?.document;
      if (iframeDoc) {
        iframeDoc.open();
        iframeDoc.write(`
          <html>
          <head>
            <style>
            body {
              background-color: ${mode === "light" ? "white" : "black"};
            }
            </style>
          </head>
          <body>
            <!-- Add any other content you need inside the iframe here -->
          </body>
          </html>
        `);

        iframeDoc.close();
      }
    }
    //same for mobile
    if (iframeMobile) {
      const iframeDoc = iframeMobile.contentDocument || iframeMobile.contentWindow?.document;
      if (iframeDoc) {
        iframeDoc.open();
        iframeDoc.write(`
          <html>
          <head>
            <style>
            body {
              background-color: ${mode === "light" ? "white" : "black"};
            }
            </style>
          </head>
          <body>
            <!-- Add any other content you need inside the iframe here -->
          </body>
          </html>
        `);

        iframeDoc.close();
      }
    }

    //after 300ms setup the iframes again
    setTimeout(() => {
      setupIframes();
    }, 300);

  }

  return (
    <div className="flex flex-col h-full">
      <div className="bg-gray-50 dark:bg-gray-950">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div className="max-w-7xl py-4 px-4 sm:px-6 lg:px-8">
              <h1 className="text-2xl font-semibold text-gray-900 dark:text-gray-50">Chatbot Preview</h1>
            </div>
            <div className={`cursor-pointer px-4 py-2 rounded-md ${activeTab === "desktop" ? "bg-gray-900 text-white dark:bg-white dark:text-black" : "bg-white text-gray-900 hover:bg-gray-100 dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800"} mr-2`} onClick={() => setActiveTab("desktop")}>Desktop</div>
            <div className={`cursor-pointer px-4 py-2 rounded-md ${activeTab === "mobile" ? "bg-gray-900 text-white dark:bg-white dark:text-black" : "bg-white text-gray-900 hover:bg-gray-100 dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800"}`} onClick={() => setActiveTab("mobile")}>Mobile</div>
          </div>
          <div>
            <div
              className={"cursor-pointer px-4 py-2 rounded-md bg-white text-gray-900 hover:bg-gray-100 dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 mr-2"} onClick={() => restartChatbot()}>Restart Chatbot</div>
          </div>
        </div>
      </div>
      <div className="relative flex-1 grow">
        <div className={`absolute top-0 px-5 pb-5 left-0 w-full h-full  flex justify-center items-center bg-gray-50 dark:bg-gray-950 ${activeTab === "desktop" ? "block" : "hidden"}`}>
          <iframe
            key={key}
            ref={iframeRef}
            className="rounded-lg shadow-xl bg-white dark:bg-black w-full h-full"
            style={{ border: "none" }} // Adjust the height as needed
          />
        </div>
        <div className={`absolute top-0 px-5 pb-5 left-0 w-full h-full flex justify-center items-center bg-gray-50 dark:bg-gray-950 ${activeTab === "mobile" ? "block" : "hidden"}`}>
          <iframe
            key={key}
            ref={iframeMobileRef}
            className="rounded-lg shadow-xl bg-white  dark:bg-black w-full h-full max-h-[852px] max-w-[393px]"
            style={{ border: "none" }} // Adjust the height as needed
          />
        </div>

      </div>
    </div>
  )

}
